import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from '../../supabaseClient/supabase';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useTheme } from '../../context/ThemeContext';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem('sb-airfaodbbztjqjkvkcyq-auth-token');
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  const handleLogoutClick = () => {
    setLogoutDialog(true);
  };

  const handleLogoutCancel = () => {
    setLogoutDialog(false);
  };

  const handleLogoutConfirm = async () => {
    try {
      setLoggingOut(true);
      const { error } = await signOut();
      if (!error) {
        // Clear all localStorage items
        localStorage.removeItem('sb-airfaodbbztjqjkvkcyq-auth-token');
        localStorage.removeItem('token');
        localStorage.removeItem('user_info');
        localStorage.clear();
        navigate('/login');
      } else {
        console.error('Error during logout:', error.message);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoggingOut(false);
      setLogoutDialog(false);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const buttonStyle = (path) => ({
    borderBottom: isActive(path) ? '2px solid' : 'none',
    borderRadius: 0,
    paddingBottom: '4px',
    '&:hover': {
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
  });

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, color: 'primary.main', fontWeight: 600 }}
            >
              EasyQuickAI
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <IconButton
                onClick={toggleTheme}
                sx={{ color: 'primary.main' }}
              >
                {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
              {!user ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => navigate('/home')}
                    sx={buttonStyle('/home')}
                  >
                    Image Generator
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => navigate('/dashboard')}
                    sx={buttonStyle('/dashboard')}
                  >
                    Pod Management
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => navigate('/pod-setup')}
                    sx={buttonStyle('/pod-setup')}
                  >
                    Pod Setup
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleLogoutClick}
                    startIcon={<LogoutIcon />}
                  >
                    Logout
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={handleLogoutCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to log out? Any unsaved changes will be lost.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleLogoutCancel} 
            disabled={loggingOut}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            color="error"
            variant="contained"
            disabled={loggingOut}
            startIcon={loggingOut ? <CircularProgress size={20} /> : <LogoutIcon />}
          >
            {loggingOut ? 'Logging out...' : 'Logout'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;