import { Container, Grid2, Paper, Skeleton, Typography, IconButton, Snackbar, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PodModal from './component/PodModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { createClient } from '@supabase/supabase-js'

const authToken = JSON.parse(localStorage.getItem('sb-airfaodbbztjqjkvkcyq-auth-token'));
const accessToken = authToken?.access_token;
const RUNPOD_API_URL = "https://runmanagement-main.onrender.com";
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const PodSetup = () => {
    const [pods, setPods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' // 'error', 'warning', 'info', 'success'
    });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity,
        });
    };

    useEffect(() => {
        if (accessToken) {
            fetchPods();
        }
    }, [accessToken]);

    const fetchPods = async () => {
        if (!accessToken) {
            showSnackbar('No access token available', 'error');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(`${RUNPOD_API_URL}/pods`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            setPods(Array.isArray(response.data) ? response.data : []);
            setError(null);
            showSnackbar('Pods fetched successfully');
        } catch (err) {
            setError('Failed to fetch pods');
            console.error(err);
            setPods([]);
            showSnackbar('Failed to fetch pods', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handlePodDelete = async (podId) => {
        if (!podId) {
            showSnackbar('Pod ID is required', 'error');
            return;
        }
        try {
            const response = await axios.delete(`${RUNPOD_API_URL}/pods/delete?pod_id=${podId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            await supabase.from('pod_activities').insert([{
                user_id: authToken.user.id,
                pod_id: podId,
                action_type: 'DELETE',
                pod_status: 'DELETED'
            }]);

            fetchPods();
            showSnackbar('Pod deleted successfully');
        } catch (err) {
            console.error(err);
            showSnackbar('Failed to delete pod', 'error');
        }
    };

    const handleCreatePod = async (podConfig) => {
        try {
            // First create user profile if it doesn't exist
            const { error: profileError } = await supabase
                .from('user_profiles')
                .upsert({
                    id: authToken.user.id,
                    full_name: authToken.user.user_metadata.full_name,
                    email: authToken.user.email,
                    total_pod_starts: 0,
                    total_pod_stops: 0
                });

            if (profileError) throw profileError;

            const response = await axios.post(`${RUNPOD_API_URL}/pods/create`, podConfig, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            
            await supabase.from('pod_activities').insert([{
                user_id: authToken.user.id,
                pod_id: response.data.id,
                pod_name: podConfig.name,
                action_type: 'CREATE',
                pod_type: podConfig.runtime,
                gpu_count: podConfig.gpuCount || 0
            }]);

            fetchPods();
            showSnackbar('Pod created successfully');
        } catch (error) {
            console.error('Error creating pod:', error);
            showSnackbar('Failed to create pod', 'error');
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <PodModal fetchPods={fetchPods} showSnackbar={showSnackbar} />
            <Grid2 container spacing={3}>
                <Grid2 item xs={12} width="100%">
                    {loading ? (
                        <Skeleton variant="rectangular" height={200} width="100%" />
                    ) : pods?.length > 0 ? (
                        pods.map((pod) => (
                            <Paper
                                key={pod.id}
                                elevation={0}
                                sx={{
                                    p: 3,
                                    mb: 2,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 2px 16px rgba(0,0,0,0.08)',
                                }}
                            >
                                <div>
                                    <Typography variant="h6">{pod.name}</Typography>
                                    <Typography variant="body1">Status: {pod.status}</Typography>
                                    <Typography variant="body2">{pod.last_status_change}</Typography>
                                </div>
                                <IconButton onClick={() => handlePodDelete(pod.id)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Paper>
                        ))
                    ) : (
                        <Typography variant="h5" component="h1" sx={{ fontWeight: 600, width: '100%' }}>
                            No pods found
                        </Typography>
                    )}
                </Grid2>
            </Grid2>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default PodSetup