import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { PlayArrow, Stop, Refresh } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Dashboard = () => {
  const [pods, setPods] = useState([]);
  const [selectedPod, setSelectedPod] = useState('');
  const [podStatus, setPodStatus] = useState(null);
  const [backendStatus, setBackendStatus] = useState('checking');
  const [showStartMessage, setShowStartMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const authToken = JSON.parse(localStorage.getItem('sb-airfaodbbztjqjkvkcyq-auth-token'));
  const accessToken = authToken?.access_token;
  const RUNPOD_API_URL = "https://runmanagement-main.onrender.com";

  console.log("localhost ~", localStorage);

  useEffect(() => {
    fetchPods();
  }, []);

  const fetchPods = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${RUNPOD_API_URL}/pods`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setPods(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (err) {
      setError('Failed to fetch pods');
      console.error(err);
      setPods([]);
    } finally {
      setLoading(false);
    }
  };

  const handlePodSelect = async (podId) => {
    setSelectedPod(podId);
    if (podId) {
      await checkPodStatus(podId);
    }
  };

  const logPodActivity = async (podId, actionType, podDetails) => {
    try {
      // First create user profile if it doesn't exist
      const { error: profileError } = await supabase
        .from('user_profiles')
        .upsert({
          id: userInfo.id,
          full_name: userInfo.full_name,
          email: userInfo.email,
          total_pod_starts: 0,
          total_pod_stops: 0
        });

      if (profileError) throw profileError;

      // Log pod activity
      await supabase.from('pod_activities').insert([{
        user_id: userInfo.id,
        pod_id: podId,
        pod_name: podDetails?.name || podId,
        action_type: actionType,
        pod_status: podDetails?.status,
        pod_type: podDetails?.runtime?.name,
        gpu_count: podDetails?.gpuCount || 0
      }]);

      // Update user profile statistics
      const field = actionType === 'START' ? 'total_pod_starts' : 'total_pod_stops';
      await supabase.from('user_profiles')
        .update({ [field]: supabase.raw(`${field} + 1`) })
        .eq('id', userInfo.id);

    } catch (error) {
      console.error('Failed to log activity:', error);
    }
  };

  const handleStartPod = async (selectedPod) => {
    if (!selectedPod) return;
    try {
      setLoading(true);
      await axios.post(`${RUNPOD_API_URL}/pods/start?pod_id=${selectedPod}`, null, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      
      const podDetails = await checkPodStatus(selectedPod);
      await logPodActivity(selectedPod, 'START', podDetails);
      
      setOpenDialog(true);
      await fetchPods();
      setError(null);
    } catch (err) {
      setError('Failed to start pod');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleStopPod = async () => {
    if (!selectedPod) return;
    try {
      setLoading(true);
      await axios.post(`${RUNPOD_API_URL}/pods/stop?pod_id=${selectedPod}`, null, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      
      const podDetails = await checkPodStatus(selectedPod);
      await logPodActivity(selectedPod, 'STOP', podDetails);
      
      await fetchPods();
      setError(null);
    } catch (err) {
      setError('Failed to stop pod');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const checkPodStatus = async (podId) => {
    try {
      const response = await axios.get(`${RUNPOD_API_URL}/pods/${podId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setPodStatus(response.data);
      return response.data;
    } catch (err) {
      console.error('Failed to fetch pod status:', err);
      return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'running':
        return 'success.main';
      case 'stopped':
        return 'error.main';
      case 'starting':
        return 'warning.main';
      default:
        return 'text.secondary';
    }
  };

  const handleNavigateHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: '0 2px 16px rgba(0,0,0,0.08)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h5" component="h1" sx={{ fontWeight: 600 }}>
                Pod Management
              </Typography>
              <Button
                startIcon={<Refresh />}
                onClick={fetchPods}
                disabled={loading}
              >
                Refresh Pods
              </Button>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Select Pod</InputLabel>
              <Select
                value={selectedPod}
                label="Select Pod"
                onChange={(e) => handlePodSelect(e.target.value)}
                disabled={loading}
              >
                {pods.map((pod) => (
                  <MenuItem key={pod.id} value={pod.id}>
                    {pod.name || pod.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {podStatus && (
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  Pod Status:
                  <Box
                    component="span"
                    sx={{
                      color: podStatus.status === 'RUNNING' ? 'success.main' : 
                             podStatus.status === 'EXITED' ? 'error.main' : 'warning.main',
                      fontWeight: 'bold'
                    }}
                  >
                    {podStatus.status}
                  </Box>
                </Typography>
                {podStatus.status === 'RUNNING' && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Backend is ready and running
                  </Alert>
                )}
              </Box>
            )}

            <Box sx={{ display: 'flex', gap: 2 }}>
              {podStatus?.status !== 'RUNNING' && (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<PlayArrow />}
                  onClick={() => handleStartPod(selectedPod)}
                  disabled={loading || !selectedPod}
                >
                  Start Pod
                </Button>
              )}
              {podStatus?.status === 'RUNNING' && (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Stop />}
                  onClick={handleStopPod}
                  disabled={loading || !selectedPod}
                >
                  Stop Pod
                </Button>
              )}
            </Box>

            <Snackbar
              open={showStartMessage}
              autoHideDuration={6000}
              onClose={() => setShowStartMessage(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert onClose={() => setShowStartMessage(false)} severity="info" sx={{ width: '100%' }}>
                Please wait 3-4 minutes for the server to be ready. You can return to the home page.
              </Alert>
            </Snackbar>

            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
          Pod Started Successfully
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="body1">
            The pod is starting up. It may take 3-4 minutes for the server to be fully ready.
            Would you like to return to the home page?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Stay Here
          </Button>
          <Button 
            onClick={handleNavigateHome} 
            variant="contained" 
            color="primary"
            autoFocus
          >
            Go to Home
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Dashboard; 